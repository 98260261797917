import { registerApplication, start } from "single-spa";
import PubSub from "pubsub-js";

const publish = (event, data) => PubSub.publish(event, data);
const subscribe = (event, callback) => PubSub.subscribe(event, callback);

const variables = {
  "targetEnvironment": process.env.ENVIRONMENT,
  "clientId": process.env.REACT_APP_CLIENT_ID,
  "apiUrl": process.env.API_URL,
  "apiVersion": process.env.API_VERSION,
  "orgSignUp": process.env.ORG_SIGNUP,
  "userSignUp": process.env.USER_SIGNUP,
  "signin": process.env.SIGNIN,
  "inviteUser": process.env.INVITE_USER,
  "authorityOrgSignUp": process.env.AUTHORITY_ORG_SIGNUP,
  "authorityUserSignUp": process.env.AUTHORITY_USER_SIGNUP,
  "authoritySignin": process.env.AUTHORITY_SIGNIN,
  "authorityInviteUser": process.env.AUTHORITY_INVITE_USER,
  "authorityDomain": process.env.AUTHORITY_DOMAIN,
  "apiScopes": process.env.API_SCOPE,
  "blobContainer": process.env.BLOB_CONTAINER,
  "blobStorageAccountName": process.env.BLOB_STORAGE_ACCOUNT_NAME,
  "blobSASToken": process.env.BLOB_SAS_TOKEN,
  "authorityESign": process.env.AUTHORITY_E_SIGN,
  "eSign": process.env.ESIGN,
  "iFrameLoaded": process.env.IFRAMELOADED,
  "eSignTrustUrl": process.env.ESIGN_TRUSTURL,
  "reactAppServerURl": process.env.REACT_APP_SERVER_URL,
  "gateWayUrl": process.env.GATEWAY_URL,
  "ssoEsignUrl": process.env.SSO_Esign_Url
};
sessionStorage.setItem("envVariables", JSON.stringify(variables));

const registerApps = (appsToBeRegistered) => {
  const apps = [
    {
      name: "@valgenesis/dashboard",
      app: () => System.import("@valgenesis/dashboard"),
      activeWhen: ["/dashboard"],
    },
    {
      name: "@valgenesis/manage-users",
      app: () => System.import("@valgenesis/manage-users"),
      activeWhen: ["/admin/", "/reports/", "/system/"],
    },
    {
      name: "@valgenesis/manage-system",
      app: () => System.import("@valgenesis/manage-system"),
      activeWhen: ["/system/"],
    },
    {
      name: "@valgenesis/vconnect",
      app: () => System.import("@valgenesis/vconnect"),
      activeWhen: ["/vconnect"],
    },
    {
      name: "@valgenesis/components-host",
      app: () => System.import("@valgenesis/components-host"),
      activeWhen: ["/system"],
    },
    {
      name: "@valgenesis/reports",
      app: () => System.import("@valgenesis/reports"),
      activeWhen: ["/reports"]
    },
    {
      name: "@valgenesis/riskmanager",
      app: () => System.import("@valgenesis/riskmanager"),
      activeWhen: ["/risk-manager"]
    }
  ];

  apps.forEach((app) => {
    registerApplication({
      ...app,
      customProps: { publish, subscribe, variables },
    });
  });
};

registerApplication({
  name: "@valgenesis/layout",
  app: () => System.import("@valgenesis/layout"),
  activeWhen: ["/"],
  customProps: { publish, subscribe, variables, registerApps }
});

start({ urlRerouteOnly: true, });








